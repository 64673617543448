<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Exam Routine
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs12>
                                <v-select :items="exams" class="pa-0" label="Exam" v-model="exam_id" outlined dense/>
                            </v-flex>
                        </v-card-title>
                    </v-card>
                    <v-data-table
                            :headers="headers"
                            hide-default-footer
                            :options.sync="pagination"
                            :items="form.items.data"
                            :loading="form.loading">
                        <template v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">
                                    <strong>{{ item.subject.name | capitalize }}</strong> <br>
                                    <small style="color:#555">
                                    <span style="color:red;"
                                          v-if="/शनिबार/.test(getDateFormat(form.items.data[index].exam_date))">
                                        {{getDateFormat(form.items.data[index].exam_date)}}
                                    </span>
                                        <span v-else>
                                            {{getDateFormat(form.items.data[index].exam_date)}}
                                    </span>
                                    </small>
                                </td>
                                <td class="text-xs-left">
                                    {{item.exam_date}}
                                    <!--{{form.items.data[index].exam_date}}-->

                                </td>
                                <td class="text-xs-left">
                                    <div style="display: flex">
                                        {{item.start_time}}
                                    </div>
                                </td>
                                <td class="text-xs-right">
                                    <div style="display: flex">
                                        {{item.end_time}}
                                    </div>
                                </td>
                            </tr>
                        </template>

                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();
    import Pagination from '@/library/Mixins';

    export default {
        mixins: [Pagination],

        data: () => ({
            updateState: false,
            form: new Form({
                exam_id: '',
                grade_id: '',
                routines: '',
            }, '/api/exam-routine'),
            search: null,
            startTimeDialog: false,
            endTimeDialog: false,
            pagination: {
                itemsPerPage: 20,
            },
            rowsPerPageItems: [5, 10, 15, 20],
            subject: '',
            exam_id: '',
            exams: [],
            grade: '',
            is_publish: false,
            grades: [],
            loadingGrade: false,
            is_final: false,
            headers: [
                {text: 'SN', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Subject', align: 'left', value: 'subject', sortable: false, width: 370},
                {text: 'Exam Date', align: 'centre', value: 'exam_date', width: 230, sortable: false},
                {text: 'Exam Start Time', align: 'centre', value: 'end_time', sortable: false, width: 150},
                {text: 'Exam End Time', align: 'centre', value: 'end_time', sortable: false, width: 150},
            ],
            ampm: [
                {value: 'AM', text: 'AM'},
                {value: 'PM', text: 'PM'}
            ]
        }),
        computed: {
            ...mapState(['batch'])
        },
        mounted() {
            this.getExams();
            const student = JSON.parse(this.$storage.get('_s_ch'));
            this.grade = student.grade_id;

        },
        watch: {
            'batch': function (value) {
            },
            'grade': function () {
                this.get();
            },
            'exam_id': function (v) {
                this.get();
                // this.getGrades();
            }
        },

        methods: {


            getDateFormat(date) {
                try {
                    let dateInstance = new NepaliDate(date.dateForm());
                    return dateInstance.format('mmmm d, yyyy dddd');
                } catch (e) {
                    // this.updateState = false;
                    return '-';
                }
            },

            get(params) {
                if (this.exam_id && this.grade) {
                    let query = [null, undefined].includes(params) ? this.queryString('&batchId=' + this.batch.id + '&gradeId=' + this.grade + '&examId=' + this.exam_id) : params;
                    this.form.get(null, query).then(({data}) => {
                        this.is_publish = data.isPublished;
                        let d = [];

                        d = this.form.items.data.map(res => {
                            if (!res.st_ampm) {
                                res.st_ampm = 'AM';
                            }
                            if (!res.et_ampm) {
                                res.et_ampm = 'PM';
                            }
                            if (!res.exam_date) {
                                res.exam_date = nd.format('YYYY-MM')
                            }
                        });
                    })
                }

            },


            getExams() {
                this.$rest.get('/api/exam').then(({data}) => {
                    this.exams = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            }

        }
    }
</script>
<style lang="scss" scoped>
    .marks_entry {
        border: 1px solid #ccc;
        font-weight: bold;
        border-radius: 3px;
        width: 142px;
        height: 25px !important;
        text-align: center !important;
    }

    input[disabled], select[disabled] {
        background-color: #eee;
        color: #ccc;
        cursor: not-allowed;
    }

    .theme--light.v-select .v-select__selections {
        margin-top: -27px !important;
        margin-left: 8px !important;
    }

    input:focus {
        outline: none;
    }

    select:focus {
        outline: none;
    }

</style>